
































































import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiGoodsSettings, apiGoodsSettingsAdd } from '@/api/setting/goods'
import { GoodsSettings_Req } from '@/api/setting/goods.d.ts'
@Component({
    components: {
        MaterialSelect
    }
})
export default class SettingGoods extends Vue {
    /** S Data **/
    // 表单数据
    goodsSettings: GoodsSettings_Req = {
        image: '', //商品主图
        is_show: 0 //是否显示:0-否;1-是;
    }
    /** E Data **/

    // 获取商品设置
    getGoodsSettings() {
        apiGoodsSettings()
            .then((res: any) => {
                this.goodsSettings = res
                // this.$message.success('数据请求成功!')
            })
            .catch(() => {
                // this.$message.error('数据请求失败!')
            })
    }
    // 修改商品设置
    putGoodsSettings() {
        apiGoodsSettingsAdd(this.goodsSettings)
            .then((res: any) => {
                // this.$message.success('保存成功!')
                this.getGoodsSettings()
            })
            .catch(() => {
                // this.$message.error('保存失败!')
            })
    }
    /** S Life Cycle **/
    created() {
        this.getGoodsSettings()
    }
    /** E Life Cycle **/
}
